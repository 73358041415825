import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import { selectJobCascade, updateVerifierSetting } from '@/api/material';
import Cookies from "js-cookie";
export default defineComponent({
  props: {
    respVisible: {
      type: Boolean,
      default: false
    },
    respData: {
      type: Object,
      default: () => ({
        professionals: [],
        rests: [],
        warehouses: []
      })
    }
  },
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined
  },
  emits: ['closeRespVisible'],

  setup(props, context) {
    const closeRespVisible = () => {
      context.emit('closeRespVisible', false);
    }; //添加责任人


    const addResp = () => {
      formState.rests.push([]);
    }; //删除责任人


    const deleteResp = index => {
      formState.rests.splice(index, 1);
    };

    const loading = ref(false);
    const formRef = ref();
    const formState = reactive({
      itemName: props.respData.itemName,
      itemId: props.respData.itemId,
      rests: props.respData.rests.length ? props.respData.rests : [[]]
    });
    const rules = {
      itemName: [{
        required: true,
        message: '审批流程线名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    }; //提交

    const handleOk = async () => {
      formRef.value.validate().then(async () => {
        loading.value = true;
        const param = {
          type: 11,
          belongProject: Number(Cookies.get("belongProject")),
          rests: [],
          // personnels:[],
          itemName: formState.itemName,
          itemId: formState.itemId ? formState.itemId : null
        };
        param.rests = getObj(formState.rests, 0);

        try {
          let res = await updateVerifierSetting(param);

          if (res.code == 200) {
            _message.success(res.message);

            context.emit('closeRespVisible', false);
            loading.value = false;
          } else {
            _message.error(res.message);

            loading.value = false;
          }
        } catch (error) {
          loading.value = false;
          console.log(error);
        }
      });
    };

    const getObj = (val, num) => {
      let arrData = [];
      val.forEach(item => {
        let arr = num == 1 ? item.value[1].split('-') : item[1].split('-');
        let obj = {
          professionalId: item.id ? item.id : null,
          professionalName: item.name ? item.name : null,
          projectId: Number(arr[1]),
          idCard: arr[0],
          jobId: num == 1 ? item.value[0] : item[0],
          belongProject: Number(Cookies.get("belongProject")),
          userName: arr[2]
        }; // console.log(obj)

        arrData.push(obj);
      });
      return arrData;
    };

    const change = (val, selectedOptions) => {
      console.log(val, selectedOptions);
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobCorporations = res.data.jobCorporations; //总公司管理岗集合

        const jobProjects = res.data.jobProjects; //本项目管理岗集合

        options.value = conversion(jobProjects); // console.log(options.value)
      }
    };

    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            obj.children.push({
              value: `${ixt.uid}-${ixt.belongProject}-${ixt.userName}`,
              label: ixt.userName,
              projectId: ixt.belongProject
            });
          });
        }

        arr.push(obj);
      });
      return arr;
    };

    const handleChange = val => {
      console.log(val);
    };

    onMounted(() => {
      getJobCascade();
    });
    return {
      options,
      closeRespVisible,
      addResp,
      deleteResp,
      loading,
      handleOk,
      change,
      handleChange,
      formRef,
      formState,
      rules
    };
  }

});