import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { ProfileOutlined, DollarOutlined, BranchesOutlined, PartitionOutlined, ImportOutlined } from '@ant-design/icons-vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import moment from 'moment';
import { selectOvertimeApplyById, updateToOvertimeApproval, updateOvertimeApply } from '@/api/officeApi'; // import { useStore } from 'vuex'

import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    ProfileOutlined,
    DollarOutlined,
    uploadImgs,
    BranchesOutlined,
    PartitionOutlined,
    ImportOutlined
  },
  props: {
    // applyVisible: {
    //   type: Boolean,
    //   default: false
    // },
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closetravelApplyVisible'],

  setup(props, context) {
    // const store = useStore()
    const formRef = ref();
    const formState = reactive({
      theme: "",
      calcMethod: undefined,
      time: [undefined],
      overtimeCause: '',
      overtimeTimeLong: '',
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      content: "",
      //审批意见
      itemId: null
    });
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      overtimeCause: [{
        required: true,
        message: '加班事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      calcMethod: [{
        required: true,
        message: '计算方式不能为空',
        trigger: 'change',
        type: 'number'
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };

    let validateOverTime = async (rule, value) => {
      if (!value || value.length == 0) {
        return Promise.reject('加班时间不能为空');
      } else {
        console.log(value);
        let time = moment(value[1]).valueOf() - moment(value[0]).valueOf();

        if (time === 0) {
          return Promise.reject('加班时长不能少于0小时');
        }

        if (time / 3600000 > 24) {
          return Promise.reject('单个时间段最长只能跨越一天');
        }

        return Promise.resolve();
      }
    };

    const closetravelApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closetravelApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        //详情
        return true;
      } else if (props.applyDetailData.state == 1) {
        //审批
        return true;
      } else if (props.applyDetailData.state == 2) {
        //修改
        return false;
      }
    });

    const getData = async key => {
      const res = await selectOvertimeApplyById(key);

      try {
        Object.assign(formState, res.data);
        let arr = formState.overtimeStr.split(',');
        let arr1 = [];
        arr.forEach(item => {
          arr1.push(item.split('~'));
        });
        formState.time = arr1; // console.log(formState)
      } catch (error) {
        console.log('加班详情:', error);
      }
    }; //审批


    const handlePassOrReject = async (num, type) => {
      if (!formState.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.applyId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject
      };
      loading.value = true;

      try {
        const res = await updateToOvertimeApproval(param);

        if (res.code == 200) {
          _message.success(res.message);

          loading.value = false;
          context.emit('closetravelApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }
      } catch (error) {
        loading.value = false;
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "审批责任人";

        case 4:
          return "财务审批人";

        case 5:
          return "人事审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //修改

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        let isOk = false;
        isOk = findDataIsOk(formState.time); // console.log(isOk)

        if (isOk) {
          _message.error('加班时间段不能有重合');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        let arr = [];
        obj.time.forEach(item => {
          arr.push(item.join("~"));
        });
        obj.overtimeStr = arr.join(",");
        obj.overtimeTimeLong = overtimeTimeLong.value;
        loading.value = true;
        let res = await updateOvertimeApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closetravelApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const findDataIsOk = times => {
      for (let i = 0; i < times.length; i++) {
        for (let j = 0; j < times.length; j++) {
          let is = moment(times[i][0]).valueOf();
          let ie = moment(times[i][1]).valueOf();
          let js = moment(times[j][0]).valueOf();
          let je = moment(times[j][1]).valueOf();

          if (i == j) {
            if (is == ie) {
              return true;
            } else {
              continue;
            }
          } else {
            if (is <= je && is >= js || ie >= js && ie <= je || is <= js && ie >= je || is >= js && ie <= je) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleAdd = () => {
      formState.time.push(undefined);
    };

    const onDelete = key => {
      formState.time.splice(key, 1);
    };

    const overtimeTimeLong = computed(() => {
      let sum = 0;
      formState.time.forEach(item => {
        if (Array.isArray(item)) {
          sum += moment(item[1]).valueOf() - moment(item[0]).valueOf();
        }
      });

      if (/[\.]/.test(sum / 3600000)) {
        return (sum / 3600000).toFixed(2);
      } else {
        return sum / 3600000;
      }
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 11,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getData(props.applyDetailData.key);
      getApprovalItem();
    });
    return {
      formRef,
      formState,
      rules,
      isDisabledForCl,
      closetravelApplyVisible,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      handleOk,
      loading,
      disabledDate,
      handleAdd,
      onDelete,
      overtimeTimeLong,
      validateOverTime,
      projectOptions
    };
  }

});