/*
 * 办公相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
//报销申请接口
export const insertExpenseApply = (param) => {
  return axios.post(`/personnelapi/knExpenseApply/insertExpenseApply`, param);
}
//报销列表接口
export const selectExpenseApplyByPagination = (param) => {
  return axios.post(`/personnelapi/knExpenseApply/selectExpenseApplyByPagination`, param);
}
//报销详情接口
export const selectExpenseApplyById = (param) => {
  return axios.get(`/personnelapi/knExpenseApply/selectApplyById?id=${param}`);
}
//报销审批接口
export const updateToExpenseApproval = (param) => {
  return axios.post(`/personnelapi/knExpenseApply/updateToApproval`, param);
}
//报销修订接口
export const updateReviseApply = (param) => {
  return axios.post(`/personnelapi/knExpenseApply/updateReviseApply`, param);
}
//报销修改接口
export const updateExpenseApply = (param) => {
  return axios.post(`/personnelapi/knExpenseApply/updateExpenseApply`, param);
}
//查询假期记录
export const selectHolidayRecordByPagination = (param) => {
  return axios.post(`/wlapi/knHolidayRecord/selectHolidayRecordByPagination`, param);
}
//查询假期设置
export const selectHolidaySet = () => {
  return axios.post(`/wlapi/knHolidaySetting/selectHolidaySet`);
}
//假期设置
export const insertOrUpdateHolidaySet = (param) => {
  return axios.post(`/wlapi/knHolidaySetting/insertOrUpdateHolidaySet`, param);
}
// 查询历史请假记录
export const selectHolidayHistoryByPagination = (param) => {
  return axios.post(`/wlapi/knHolidayHistory/selectHolidayHistoryByPagination`, param);
}
// 请假申请接口
export const insertLeaveApply = (param) => {
  return axios.post(`/wlapi/knLeaveApply/insertLeaveApply`, param);
}
// 请假申请列表
export const selectLeaveApplyByPagination = (param) => {
  return axios.post(`/wlapi/knLeaveApply/selectLeaveApplyByPagination`, param);
}
// 请假申请修改
export const updateLeaveApply = (param) => {
  return axios.post(`/wlapi/knLeaveApply/updateLeaveApply`, param);
}
// 请假申请详情
export const selectLeaveApplyById = (param) => {
  return axios.get(`/wlapi/knLeaveApply/selectLeaveApplyById?id=${param}`);
}
// 请假申请审批
export const updateToLeaveApproval = (param) => {
  return axios.post(`/wlapi/knLeaveApply/updateToLeaveApproval`, param);
}
// 请假申请撤销
export const updateRevokeApply = (param) => {
  return axios.post(`/wlapi/knLeaveApply/updateRevokeApply`, param);
}

//出差列表接口
export const selectEvectionApplyByPagination = (param) => {
  return axios.post(`/personnelapi/knEvectionApply/selectEvectionApplyByPagination`, param);
}
//费用归属合同查询接口
export const selectBelongContract = (param) => {
  return axios.post(`/personnelapi/knEvectionApply/selectBelongContract`, param);
}
//出差申请接口
export const insertEvectionApply = (param) => {
  return axios.post(`/personnelapi/knEvectionApply/insertEvectionApply`, param);
}
//出差详情接口
export const selectTravelApplyById = (param) => {
  return axios.get(`/personnelapi/knEvectionApply/selectApplyById?id=${param}`);
}
//出差审批接口
export const updateTravelToApproval = (param) => {
  return axios.post(`/personnelapi/knEvectionApply/updateToApproval`, param);
}
//出差修改接口
export const updateEvectionApply = (param) => {
  return axios.post(`/personnelapi/knEvectionApply/updateEvectionApply`, param);
}
//出差撤销接口
export const updateTravelRevokeApply = (param) => {
  return axios.post(`/personnelapi/knEvectionApply/updateRevokeApply`, param);
}
//查询未选中的出差审批接口
export const selectEvectionApply = (param) => {
  return axios.post(`/personnelapi/knExpenseApply/selectEvectionApply`, param);
}

// 加班申请列表
export const selectOvertimeApplyByPagination = (param) => {
  return axios.post(`/wlapi/knOvertimeApply/selectOvertimeApplyByPagination`, param);
}

// 加班申请接口
export const insertOvertimeApply = (param) => {
  return axios.post(`/wlapi/knOvertimeApply/insertOvertimeApply`, param);
}

//加班详情接口
export const selectOvertimeApplyById = (param) => {
  return axios.get(`/wlapi/knOvertimeApply/selectOvertimeApplyById?id=${param}`);
}

// 加班审批接口
export const updateToOvertimeApproval = (param) => {
  return axios.post(`/wlapi/knOvertimeApply/updateToOvertimeApproval`, param);
}
// 加班修改接口
export const updateOvertimeApply = (param) => {
  return axios.post(`/wlapi/knOvertimeApply/updateOvertimeApply`, param);
}

//物品申请
//物品申请查询
export const selectGoodsByPagination = (param) => {
  return axios.post(`/wlapi/knGoodsApply/selectGoodsByPagination`, param);
}
//物品申请
export const insertGoodsApply = (param) => {
  return axios.post(`/wlapi/knGoodsApply/insertGoodsApply`, param);
}
//物品详情
export const selectGoodsById = (param) => {
  return axios.get(`/wlapi/knGoodsApply/selectGoodsById?id=${param}`);
}
//物品申请修改
export const updateGoodsApply = (param) => {
  return axios.post(`/wlapi/knGoodsApply/updateGoodsApply`, param);
}
//物品申请审批
export const updateToGoodsApproval = (param) => {
  return axios.post(`/wlapi/knGoodsApply/updateToGoodsApproval`, param);
}

//查询成本中心
export const selectCostCenterByParam = (param) => {
  return axios.post(`/wlapi/knCostCenter/selectCostCenterByParam`, param);
}
//新增成本中心
export const insertCostCenter = (param) => {
  return axios.post(`/wlapi/knCostCenter/insertCostCenter`, param);
}
//删除成本中心
export const deleteCostCenterById = (param) => {
  return axios.post(`/wlapi/knCostCenter/deleteCostCenterById`, param);
}

//调休假期修改
export const updateHolidayRecord = (param) => {
  return axios.post(`/wlapi/knHolidayRecord/updateHolidayRecord`, param);
}
//查询各个假期类型剩余天数
export const selectRemainDay = (param) => {
  return axios.post(`/wlapi/knLeaveApply/selectRemainDay`, param);
}
//请假申请审批中撤销接口
export const updateIngRevokeApply = (param) => {
  return axios.post(`/wlapi/knLeaveApply/updateIngRevokeApply`, param);
}
//获取请假天数
export const getLeaveDays = (param) => {
  return axios.post(`/wlapi/knLeaveApply/getLeaveDay`, param);
}
//新增报销类型
export const insertExpenseType = (param) => {
  return axios.post(`/wlapi/knExpenseType/insertExpenseType`, param);
}
//删除报销类型
export const deleteExpenseTypeByParam = (param) => {
  return axios.post(`/wlapi/knExpenseType/deleteExpenseTypeByParam`, param);
}
//查询报销类型
export const selectExpenseTypeByParam = (param) => {
  return axios.post(`/wlapi/knExpenseType/selectExpenseTypeByParam`, param);
}
//分页加班时长统计接口
export const selectOvertimeStatisticsByPagination = (param) => {
  return axios.post(`/wlapi/knOvertimeApply/selectOvertimeStatisticsByPagination`, param);
}