import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertOvertimeApply } from '@/api/officeApi';
import moment from 'moment';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      calcMethod: undefined,
      time: [undefined],
      overtimeCause: '',
      overtimeTimeLong: '',
      itemId: null
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      validateTime
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      overtimeCause: [{
        required: true,
        message: '加班事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      calcMethod: [{
        required: true,
        message: '计算方式不能为空',
        trigger: 'change',
        type: 'number'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }] // time: [
      //   {
      //     required: true,
      //     message: '加班时间不能为空',
      //     trigger: 'change',
      //     validator: validateOverTime,
      //   },
      // ],

    };

    let validateOverTime = async (rule, value) => {
      if (!value || value.length == 0) {
        return Promise.reject('加班时间不能为空');
      } else {
        console.log(value);
        let time = moment(value[1]).valueOf() - moment(value[0]).valueOf();

        if (time === 0) {
          return Promise.reject('加班时长不能少于0小时');
        }

        if (time / 3600000 > 24) {
          return Promise.reject('单个时间段最长只能跨越一天');
        }

        return Promise.resolve();
      }
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        let isOk = false;
        isOk = findDataIsOk(formState.time); // console.log(isOk)

        if (isOk) {
          _message.error('加班时间段不能有重合');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        let arr = [];
        obj.time.forEach(item => {
          arr.push(item.join("~"));
        });
        obj.overtimeStr = arr.join(",");
        obj.overtimeTimeLong = overtimeTimeLong.value; // console.log(obj)

        loading.value = true;
        let res = await insertOvertimeApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const findDataIsOk = times => {
      for (let i = 0; i < times.length; i++) {
        for (let j = 0; j < times.length; j++) {
          let is = moment(times[i][0]).valueOf();
          let ie = moment(times[i][1]).valueOf();
          let js = moment(times[j][0]).valueOf();
          let je = moment(times[j][1]).valueOf();

          if (i == j) {
            if (is == ie) {
              return true;
            } else {
              continue;
            }
          } else {
            if (is <= je && is >= js || ie >= js && ie <= je || is <= js && ie >= je || is >= js && ie <= je) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleAdd = () => {
      formState.time.push(undefined);
    };

    const onDelete = key => {
      formState.time.splice(key, 1);
    };

    const overtimeTimeLong = computed(() => {
      let sum = 0;
      formState.time.forEach(item => {
        if (Array.isArray(item)) {
          sum += moment(item[1]).valueOf() - moment(item[0]).valueOf();
        }
      });

      if (/[\.]/.test(sum / 3600000)) {
        return (sum / 3600000).toFixed(2);
      } else {
        return sum / 3600000;
      }
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 11,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      disabledDate,
      handleAdd,
      onDelete,
      validateTime,
      overtimeTimeLong,
      validateOverTime,
      projectOptions
    };
  }

});