import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { VerticalAlignBottomOutlined, ProfileOutlined, RightOutlined, PartitionOutlined } from '@ant-design/icons-vue';
import { selectOvertimeStatisticsByPagination } from '@/api/officeApi';
import moment from 'moment';
const columns = [{
  title: '序号',
  dataIndex: 'num',
  align: 'center',
  width: 80
}, {
  title: '姓名',
  dataIndex: 'userName',
  align: 'center'
}, {
  title: '统计范围',
  dataIndex: 'date',
  align: 'center'
}, {
  title: '加班总时长(天)',
  dataIndex: 'totalOvertimeDay',
  align: 'center'
}, {
  title: '工作日加班时长(天)',
  dataIndex: 'workOvertimeDay',
  align: 'center'
}, {
  title: '休息日加班时长(天)',
  dataIndex: 'restOvertimeDay',
  align: 'center'
}, {
  title: '法定假日加班时长(天)',
  dataIndex: 'holidayOvertimeDay',
  align: 'center'
}];
export default {
  components: {
    VerticalAlignBottomOutlined,
    ProfileOutlined,
    RightOutlined,
    PartitionOutlined
  },
  emits: ['closeWorkOvertime'],

  setup(props, context) {
    const handleBack = () => {
      context.emit('closeWorkOvertime', false);
    };

    const formState = reactive({
      userName: null,
      date: [moment().format('YYYY-MM'), moment().format('YYYY-MM')]
    });
    const formRef = ref(); //表格

    const tableData = ref([]); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const tableLoading = ref(false);

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        userName: formState.userName,
        startTime: formState.date.length ? moment(formState.date[0]).format('YYYY-MM') : null,
        endTime: formState.date.length ? moment(formState.date[1]).format('YYYY-MM') : null
      };
      tableLoading.value = true;

      try {
        let res = await selectOvertimeStatisticsByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id,
              date: item.startTime !== item.endTime ? `${item.startTime} ~ ${item.endTime} ` : item.startTime
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          tableLoading.value = false;
        } else {
          tableLoading.value = false;

          _message.error(res.message);
        }
      } catch {
        tableLoading.value = false;
      }
    }; //点击查询


    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    };

    const mode2 = ref(['month', 'month']);

    const handlePanelChange = (val, mode) => {
      formState.date = val;
      mode2.value = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
    };

    const handleChange = val => {
      formState.date = val;
    };

    onMounted(() => {
      getTableData();
    });
    return {
      handleBack,
      columns,
      tableData,
      onPageChange,
      paginationConfig,
      formState,
      formRef,
      onSubmit,
      resetForm,
      tableLoading,
      handlePanelChange,
      handleChange,
      mode2
    };
  }

};